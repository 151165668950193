/*** Additional properties for icons ***/

i[class^='icon-'],
i[class*=' icon-'],
span[class^='icon-'],
span[class*=' icon-'] {
  line-height: $font-size-base;
  display: inline-block;

  &::before {
    vertical-align: middle;
  }
}

span[class^='icon-'],
span[class*=' icon-'] {
  font-family: 'icons';
}
