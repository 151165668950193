@import 'variables';

// ******************************************
// Calendar from PrimeNG
// ******************************************

.nav-prev-danger.p-calendar {
  .p-datepicker .p-datepicker-header .p-datepicker-prev {
    background-color: $error-4;
    color: $gray-1;
  }
}

.nav-next-danger.p-calendar {
  .p-datepicker .p-datepicker-header .p-datepicker-next {
    background-color: $error-4;
    color: $gray-1;
  }
}

.p-calendar .p-datepicker .p-datepicker-header {
  .p-datepicker-next:focus,
  .p-datepicker-prev:focus {
    box-shadow: none;
  }
}

.p-calendar .p-datepicker table td > span.p-highlight {
  background-color: $blue-8;
}
