@import 'variables';

.p-tabview {
  & &-panels {
    background-color: transparent;
    border: none;
    border-top: 1px solid $default-input-border;
    padding: $grid-gutter-half 0;
  }

  & &-nav li.p-highlight &-nav-link {
    font-weight: $font-weight-regular;
  }
}

$tab-menu-border-color: #c8c8c8;
$tab-menu-padding: $default-padding * 2 $default-padding * 3;
$tab-menu-border-bottom: 1px solid $tab-menu-border-color;

.p-tabmenu {
  & &-nav {
    border-bottom: $tab-menu-border-bottom;
  }

  & &-nav .p-tabmenuitem .p-menuitem-link {
    font-weight: $font-weight-regular;
    padding: $tab-menu-padding;

    &:not(.p-disabled):focus {
      box-shadow: none;
    }
  }
}

.operation-support__sidebar {
  .p-tabmenu {
    width: 115%;
    &-nav {
      border: 0 none;
    }
    &-nav .p-tabmenuitem {
      background: #f5f5f5;
      padding: 20px;
      width: 87%;
      margin-right: 0px;
      margin-bottom: 15px;
      border: $default-border;
    }

    &-nav .p-highlight {
      background: #fff;
      width: 100%;
      border: $default-border;

      .p-menuitem-link-active {
        width: 87%;
      }
    }
  }
}

.p-tabmenu .p-tabmenu-nav {
  background-color: transparent;
  border-width: 0 0 2px 0;
}

.p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
  background-color: #3b82f6;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border-width: 0 0 2px 0;
  color: #64748b;
  padding: 1rem 1.125rem 0.5rem 1.125rem;
  font-weight: 600;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, outline-color 0.2s;
  margin: 0 0 -2px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: inset none;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background-color: transparent;
  border-color: transparent;
  color: #334155;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background-color: transparent;
  border-color: $primary-button-background-color;
  color: $primary-button-background-color;
}
