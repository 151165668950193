@import 'variables';

$status-ended: $blue-1;
$status-consumed: $magenta-2;
$status-none: $attention-2;
$status-dark-text: $gray-10;
$status-last: $attention-4;

@mixin status-flag-colors($text-color, $background-color, $border-color: transparent, $top-border-color: transparent) {
  border: 1px solid $border-color;
  background-color: $background-color;
  color: $text-color;

  &::after {
    border-top-color: $top-border-color;
  }
}

.status {
  &--next {
    @include status-flag-colors($gray-12, $attention-4, $attention-4, $attention-6);
  }

  &--active {
    @include status-flag-colors($gray-1, $primary-4, $primary-4, $primary-6);
  }

  &--open {
    @include status-flag-colors($success-6, $gray-1, $success-4, $success-6);
  }

  &--checked {
    @include status-flag-colors($gray-12, $success-3, $success-3, $success-5);
  }

  &--ended {
    @include status-flag-colors($status-dark-text, $status-ended);
  }

  &--downtime {
    @include status-flag-colors($gold-6, $gray-1, $gold-4, $gold-6);
  }

  &--running {
    @include status-flag-colors($gray-1, $blue-8, $blue-8, $blue-7);
  }

  &--consumed {
    @include status-flag-colors($status-dark-text, $status-consumed);
  }

  &--finished {
    @include status-flag-colors($gray-10, $gray-4, $gray-4, $gray-8);
  }

  &--none {
    @include status-flag-colors($status-dark-text, $status-none);
  }

  &--last {
    @include status-flag-colors($status-dark-text, $status-last);
  }

  &--production-order-prod,
  &--production-order-info-pl,
  &--production-order-info-pl,
  &--virtual-container-info,
  &--production-order-info-dc {
    background-color: $light-background-color;
    color: $blue-8;
    border: 1px solid $blue-8;

    &:after {
      display: none;
    }
  }

  &--client {
    @include status-flag-colors($gray-1, $error-6);
  }

  &--production {
    @include status-flag-colors($gray-1, $mayr-melnhof-6);
  }
}
