@import 'variables';

// ******************************************
// Input Text modifying PrimeNG
// ******************************************

.p-inputtext.p-error,
.p-inputtext.p-invalid,
.p-inputtext.ng-dirty.ng-invalid {
  border-color: #a6a6a6;
}
